import React from 'react';
import { Link } from 'react-scroll';

export default function Header() {
  return (
    <nav className="bg-menuBg text-white sticky top-0 p-4 md:p-6 shadow-lg z-50 max-w-full">
      <ul className="flex space-x-3 md:space-x-8 lg:space-x-16 justify-center font-tattoo text-sm md:text-base lg:text-lg uppercase font-extrabold tracking-wider">
        <li>
          <Link to="about" smooth={true} duration={500} offset={-70} className="text-white hover:text-brightRed cursor-pointer transition duration-200 whitespace-nowrap">
            About
          </Link>
        </li>
        <li>
          <Link to="artists" smooth={true} duration={500} offset={-70} className="text-white hover:text-brightRed cursor-pointer transition duration-200 whitespace-nowrap">
            Artists
          </Link>
        </li>
        <li>
          <Link to="convention" smooth={true} duration={500} offset={-70} className="text-white hover:text-brightRed cursor-pointer transition duration-200 whitespace-nowrap">
            Convention
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500} offset={-70} className="text-white hover:text-brightRed cursor-pointer transition duration-200 whitespace-nowrap">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}
