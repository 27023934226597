import React from 'react';
import { FaInstagram, FaGlobe } from 'react-icons/fa';

export default function Convention() {
  return (
    <section id="convention" className="p-8 lg:p-16 text-center font-tattoo">
      <div className="max-w-screen-xl mx-auto">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-deepRed">St. John's Tattoo Convention</h2>
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between gap-6">
          {/* Left Column - Image with Link */}
          <div className="w-full lg:w-3/4 border-4 border-brightRed hover:border-deepRed transition duration-300 ease-in-out rounded-lg">
            <a href="https://www.stjohnstattooconvention.com" target="_blank" rel="noopener noreferrer">
              <img 
                src="/logo--SJTC.jpg" 
                alt="St. John's Tattoo Convention" 
                className="rounded-lg shadow-lg w-full h-auto hover:scale-105 hover:opacity-90 transition duration-300 ease-in-out"
              />
            </a>
          </div>
          {/* Right Column - Content */}
          <div className="w-full lg:w-1/4 text-base md:text-lg lg:text-xl text-left mt-4 lg:mt-0 lg:pl-6">
            <p>
              Trouble Bound Studio hosts the annual St. John’s Tattoo Convention, bringing hundreds of tattooists from across the country for one of Canada's premier tattoo events.
            </p>
            <p className="mt-4 flex items-center">
              <FaGlobe className="text-brightRed mr-2" />
              <a href="https://www.stjohnstattooconvention.com" target="_blank" rel="noopener noreferrer" className="text-brightRed hover:text-deepRed transition duration-200">
               Website
              </a>
            </p>
            <p className="mt-2 flex items-center">
              <FaInstagram className="text-brightRed mr-2" />
              <a href="https://instagram.com/st.johnstattooconvention" target="_blank" rel="noopener noreferrer" className="text-brightRed hover:text-deepRed transition duration-200">
                Instagram
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
