import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { MdAccessTime } from 'react-icons/md';

export default function Contact() {
  return (
    <section id="contact" className="p-8 text-center font-tattoo">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold uppercase mb-6 text-deepRed">Contact Us</h2>
      <div className="flex flex-col items-center text-lg md:text-xl lg:text-2xl space-y-4">
        <div className="flex items-center">
          <FaEnvelope className="text-brightRed mr-3 hover:text-deepRed transition duration-200" />
          <a href="mailto:troublebound@nl.rogers.com" className="text-brightRed hover:text-deepRed transition duration-200">
            troublebound@nl.rogers.com
          </a>
        </div>
        <div className="flex items-center">
          <FaMapMarkerAlt className="text-brightRed mr-3" />
          <span>132A Water Street, St. John's NL</span>
        </div>
        <div className="flex items-center">
          <FaPhone className="text-brightRed mr-3" />
          <span>709-754-5590</span>
        </div>
        <div className="flex items-center">
          <MdAccessTime className="text-brightRed mr-3" />
          <span>Tuesday-Saturday, 12:00 pm - 6:00 pm</span>
        </div>
      </div>
    </section>
  );
}
