import Header from './components/Header';
import About from './components/About';
import Artists from './components/Artists';
import Convention from './components/Convention';
import Contact from './components/Contact';

function App() {
  return (
    <div className="bg-darkBg text-white min-h-screen border-b border-gray-800"> {/* Bottom border on entire site */}
      <Header />
      <About />
      <div className="border-t border-gray-800">
        <Artists />
      </div>
      <div className="border-t border-gray-800">
        <Convention />
      </div>
      <div className="border-t border-gray-800">
        <Contact />
      </div>
    </div>
  );
}

export default App;
